import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, Subject, tap, throwError } from 'rxjs';
import { defaultConectionV1, usersApi } from '../constants/inclub-ribera-admin.constant';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  private storage: Storage | undefined;
  private _isHiddenActivitySidebar = false;
  private _hiddenActivitySidebar = new BehaviorSubject<boolean>(false);
  private refreshNeeded$ = new Subject<void>();
  URL_API = `${defaultConectionV1}`;
  URL_API_USERS = `${usersApi}`;

  constructor(private http: HttpClient) {
    this.storage = this.getStorage();
  }


  private getStorage(): Storage | undefined {
    try {
      if (typeof window !== 'undefined' && window.sessionStorage) {
        return window.sessionStorage;
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn('sessionStorage is not available:', e);
      return undefined;
    }
  }

  getStatusActivitySidebar() {
    return this._hiddenActivitySidebar.asObservable();
  }

  toggleActivitySidebar() {
    this._isHiddenActivitySidebar = !this._isHiddenActivitySidebar;
    this._hiddenActivitySidebar.next(this._isHiddenActivitySidebar);
  }

  get<T = any>(key: string): string | null | T {
    if (this.storage?.getItem(key)) {
      try {
        return JSON.parse(this.storage.getItem(key) as string) as T
      } catch (e) {
        return this.storage.getItem(key);
      }
    } else {
      return null
    }
  }

  set<T = any>(key: string, value: T): void {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  get refreshNeeded() {
    return this.refreshNeeded$.asObservable();
  }

  requestRefresh() {
    this.refreshNeeded$.next();
  }
  private getHeaders(): HttpHeaders {
    const token = this.storage?.getItem('token');
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }

  private getHeadersBO(): HttpHeaders {
    const token = localStorage.getItem('tkbo');
    if (!token) {
      console.warn('⚠ No hay token disponible en el storage.');
    }
    return new HttpHeaders({
      Authorization: token ? `Bearer ${token}` : '', 
    });
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  getState(module: string, params?: { [key: string]: any }, userId?: number) {
    const url = new URL(`${this.URL_API}/${module}`);

    // Añadir parámetros a la URL si existen
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeaders();
    return this.http
      .get(url.toString(), { headers })
      .pipe(catchError(this.handleError));
  }
  deleteData(module: string, params?: any) {
    const url = `${this.URL_API}/${module}`;
    return this.http.delete(url, params).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  getCalendarDate(roomOfferId: number) {
    const url = `${this.URL_API}/booking/calendarDate?roomOfferId=${roomOfferId}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }
  postDataBooking(module: string, params: any) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  deleteById(module: string, id: any) {
    const url = `${this.URL_API}/${module}/${id}`;
    return this.http.delete(url).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getDataApi(module: string) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getApiUsersAll() {
    const url = `${this.URL_API_USERS}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  deleteBooking(id: number) {
    return this.http.delete(`${this.URL_API}/booking/${id}`);
  }
  getData(module: string, id?: number, path?: string) {
    let url = `${this.URL_API}/${module}`;
    if (id !== undefined) {
      url += `/${id}`;
    }
    if (path) {
      url += `/${path}`;
    }
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  postData(module: any, params: any) {
    const url = `${this.URL_API}/${module}`;
    return this.http.post(url, params).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  public getDataRuc(ruc: string): Observable<{ razonSocial: String, condicion: String }> {
    const headers = this.getHeaders();
    return this.http.get<{ razonSocial: String, condicion: String }>(`${defaultConectionV1}/users/consult-ruc/${ruc}`, { headers });
  }
  postDataBookingByReceptionist(module: string, params: any) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  getKey<T = any>(key: string): string | null | T {
    if (this.storage?.getItem(key)) {
      try {
        return JSON.parse(this.storage.getItem(key) as string) as T;
      } catch (e) {
        return this.storage.getItem(key);
      }
    } else {
      return null;
    }
  }
  getDataPaymentMethods() {
    const url = `${this.URL_API}/payment-method/active`;
    const headers = this.getHeaders();
    return this.http.get<{ description: string, paymentMethodId: string, state: string }[]>(url, { headers }).pipe(catchError(this.handleError), tap(d => {
      this.storage?.setItem("payment-method", JSON.stringify(d));
    })).toPromise();
  }

  getStateByReceptionist(
    module: string,
    params?: { [key: string]: any },
    userId?: number
  ) {
    const url = new URL(`${this.URL_API}/${module}`);

    // Añadir parámetros a la URL si existen
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeaders();
    return this.http
      .get(url.toString(), { headers })
      .pipe(catchError(this.handleError));
  }

  saveBookinClientExtgDetails(module: string, bookingId: string, companions: any[]): Observable<any> {
    const url = `${this.URL_API}/${module}/${bookingId}/companions`;
    const headers = this.getHeaders(); 
    return this.http.post(url, companions, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadCompanionsDetails(module: string, bookingId: string): Observable<any> {
    const url = `${this.URL_API}/${module}/${bookingId}/companionsDetails`;
    const headers = this.getHeaders();  
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  updateClientBookingDetails(module: string, bookingId: string, companions: any[]): Observable<any> {
    const url = `${this.URL_API}/${module}/${bookingId}/companionupdate`;
    const headers = this.getHeaders(); 
    return this.http.put(url, companions, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadCompanionByDni(module: string, dni: string): Observable<any> {
    const url = `${this.URL_API}/${module}/companions/dni/${dni}`;
    const headers = this.getHeaders();  
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadCommissionAll(module: string): Observable<any> {
    const url = `${this.URL_API}/${module}/allCommission`;
    const headers = this.getHeaders();  
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadCommissionsPaged(module: string, page: number, size: number): Observable<any> {
    const url = `${this.URL_API}/${module}/commissionPaged?page=${page}&size=${size}`;
    const headers = this.getHeaders();
    
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadCommissionDetails(module: string, paymentBookId: number): Observable<any> {
    const url = `${this.URL_API}/${module}/commission-details/${paymentBookId}`;
    const headers = this.getHeaders();  
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }


  UpdateCommissionStatus(module: string, commissionId: number, status: string): Observable<any> {
    const url = `${this.URL_API}/${module}/${commissionId}/status`; 
    const headers = this.getHeaders();
    const body = { status }; 
  
    return this.http.put(url, body, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }


  loadTypeFood(module: string, typefoodid: number): Observable<any> {
    const url = `${this.URL_API}/${module}/typeFood/${typefoodid}`;
    const headers = this.getHeaders();  
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  

  loadTypeFoodTypes(module: string , type?: string, name?: string, page: number = 1, size: number = 5): Observable<any> {
    const params: any = { page, size };
    if (type) params.type = type;
    if (name) params.name = name;
  
    const url = `${this.URL_API}/${module}/typeFoodTypes`;
    const headers = this.getHeaders();
  
    return this.http.get(url, { headers, params }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  updateFullDayTypeFood(module:string,fullDayTypeFoodId: number,foodName: string,background: string,drink: string,dessert: string,entry: string,price: number,quantity: number,currencyTypeId: number,file: File, folderNumber: number): Observable<any> {
    const url = `${this.URL_API}/${module}/typeFoodUp/${fullDayTypeFoodId}?foodName=${foodName}&background=${background}&drink=${drink}&dessert=${dessert}&entry=${entry}&price=${price}&quantity=${quantity}&currencyTypeId=${currencyTypeId}&folderNumber=${folderNumber}`; 
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put(url, formData);
  }

  deleteTypeFood(module: string, TypeFoodId: number): Observable<any> {
    const url = `${this.URL_API}/${module}/typeFoodDl`;
    const headers = this.getHeaders();
    return this.http.delete(`${url}/${TypeFoodId}`, { headers }).pipe
      (map((resp: any) => resp),
        catchError(this.handleError)
      );
  }
  

  saveFullDayTypeFood(module: string, foodName: string, type: string, background: string, drink: string, dessert: string, entry: string, price: number, quantity: number, currencyTypeId: number, file: File, folderNumber: number): Observable<any> {
    const url = `${this.URL_API}/${module}/typeFoodSave?foodName=${encodeURIComponent(foodName)}&type=${encodeURIComponent(type)}&price=${price}&entry=${encodeURIComponent(entry)}&background=${encodeURIComponent(background)}&drink=${encodeURIComponent(drink)}&dessert=${encodeURIComponent(dessert)}&currencyTypeId=${currencyTypeId}&folderNumber=${folderNumber}&quantity=${quantity}`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
}

  loadTypesFoodAll(module: string): Observable<any> {
    const url = `${this.URL_API}/${module}/typeFoodall`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadTicketAll(module: string): Observable<any>{
    const url = `${this.URL_API}/${module}/all`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  updateTicketStatus(module: string, ticketId: number, updatedData: any): Observable<any> {
    const url = `${this.URL_API}/${module}/updateEntry/${ticketId}`;
    const headers = this.getHeaders();
  
    return this.http.put(url, updatedData, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  updateTicketDays(module:string, enableDays: number[]): Observable<any> {
    const url = `${this.URL_API}/${module}/update-enabled-days`;
    const headers = this.getHeaders();
    return this.http.put(url, enableDays, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadTicketDays(module: string): Observable<any> {
    const url = `${this.URL_API}/${module}/enabled-days`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadFoodTypes(module: string): Observable<any> {
    const url = `${this.URL_API}/${module}/typeFoodall`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  loadTicketById(module: string, ticketId: number): Observable<any> {
    const url = `${this.URL_API}/${module}/Entryid/${ticketId}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  addCompanionFullDay(module: string, companion: any): Observable<any> {
    const url = `${this.URL_API}/${module}/addCompanion`;
    const headers = this.getHeaders();
    return this.http.post(url, companion, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  postFulldayRegister(module: string, params: any): Observable<any> {
    const url = `${this.URL_API}/${module}/register`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getFulldayRegister(module: string, type: string,bookingStateId: number): Observable<any> {
    const url = `${this.URL_API}/${module}/reservations/${type}?bookingStateId=${bookingStateId}`; 
    const headers = this.getHeaders(); 
    return this.http.get(url, { headers }).pipe(
        map((resp: any) => resp),
        catchError(this.handleError)
    );
  }

  getFullDayId(module: string, id: number): Observable<any> {
    const url = `${this.URL_API}/${module}/reservation/${id}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getFullDayPayment(module: string): Observable<any> {
    const url = `${this.URL_API}/${module}/reservations`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getDaysenableFullday(module: string): Observable<any> {
    const url = `${this.URL_API}/${module}/enabled-days-list`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getclientFullDay(module: string, id: number): Observable<any> {
    const url = `${this.URL_API}/${module}/userclient/${id}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  postCancelFullday(module: string, id: number): Observable<any> {
    const url = `${this.URL_API}/${module}/full-day/${id}`;
    const headers = this.getHeaders();
    return this.http.post(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  postCancelAdminFullday(module: string, params: { paymentBookId: number; refuseReasonId: number; detail: string }): Observable<any> {
    const url = `${this.URL_API}/${module}/refuse?paymentBookId=${params.paymentBookId}&refuseReasonId=${params.refuseReasonId}&refuseReason=${encodeURIComponent(params.detail)}`;
    const headers = this.getHeaders();

    return this.http.post(url, {}, { headers }).pipe( 
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getMembership(module: string, name: string,idclient: number): Observable<any> {
    const url = `${this.URL_API}/${module}/insort-inclub/${name}/${idclient}`;
    const headers = this.getHeadersBO();
    return this.http.get(url, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
}
